@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

body {
  margin: 0;
  font-family: 'Ubuntu', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  font-family: 'Ubuntu';
  background-color: #273c75;
}

code {
  font-family: 'Ubuntu', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
